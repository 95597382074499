<template>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form v-loading="loading" :model="form" label-width="12rem" style="padding: 2rem;">
          <el-form-item label="微信开放平台移动应用appid">
            <el-input v-model="form.app_open_appid"></el-input>
          </el-form-item>
          <el-form-item label="微信支付商户号">
            <el-input v-model="form.mch_id"></el-input>
          </el-form-item>
          <el-form-item label="微信支付密钥">
            <el-input v-model="form.api_key"></el-input>
          </el-form-item>
          <el-form-item label="支付授权目录">
            {{payment_dir}}
          </el-form-item>
          <el-form-item >
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>

      </el-col>
      <el-col :span="12">
        <el-form v-loading="pemLoading" label-width="8rem" style="padding: 2rem;">
          <el-form-item label="apiclient_cert.pem">
            <y_upload_cert @load="loadPem" mode="cert" v-model="cert"></y_upload_cert>
          </el-form-item>
          <el-form-item label="apiclient_key.pem">
            <y_upload_cert @load="loadPem" mode="key" v-model="key"></y_upload_cert>
          </el-form-item>
          <el-form-item >
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
</template>

<script>
import y_upload_cert from "@/components/y_upload/y_upload_cert";
export default {
  name: "wxPay",
  components: {y_upload_cert},
  data(){
    return{
      form:{
        mch_id:"",
        api_key:"",
        app_open_appid:"",
      },
      loading:false,
      payment_dir:"",
      pemLoading:false,
      cert:false,
      key:false,
    }
  },
  mounted() {
    this.load();
    this.loadPem();
    this.payment_dir = location.origin + "/"
  },
  methods:{
    loadPem(){
      this.pemLoading = true;
      this.$api.sys.manager.wxPayCert().then(res=>{
        this.pemLoading = false;
        this.cert = res.cert;
        this.key = res.key;
      })
    },
    load(){
      this.loading = true;
      this.$api.sys.manager.wxPay().then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    submit(){
      this.$api.sys.manager.wxPayEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  line-height: 1rem
}
</style>